<template>
  <div>
   <CCard>
        <CCardHeader>
          Student Class Mapping
          <div class="card-header-actions">
              <CButton color="primary" @click="map_student" >Map + </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
            <b-row>
                <b-col sm="4">
                    <b-input-group size="sm">
                        <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        ></b-form-input>
                    </b-input-group>
                </b-col>
                <b-col offset-sm="5" sm="3">
                    Total Records : {{totalRows}}
                </b-col>
            </b-row><br/>
            
            <div class="table-responsive" style="font-size:14px;font-family: Arial, Helvetica, sans-serif;">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    stacked="xs"
                    show-empty
                    small
                    @filtered="onFiltered"
                    >
                        <!-- <template #cell(actions)="row">
                            <b-button variant="success"  class="btn btn-xs btn-primary" size="sm" @click="info(row.item, row.index, $event.target)">
                            Edit
                            </b-button>
                        </template> -->
                    </b-table>
                    
            </div><br/>
            <b-row>
                <b-col sm="2">
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                    ></b-pagination>
                </b-col>
                
                <b-col  offset-sm="7"  sm="3">
                    <b-form-group horizontal label="Per page" label-size="sm" :label-cols="6" >
                    <b-form-select :options="pageOptions" v-model="perPage" size="sm"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </CCardBody>
   </CCard>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "StudentClassMap",
  components: {},
  data: function() {
    return {
        form: {
        userName: "",
        studentName:"",
        className:"",
        password: "",
        checked: []
      },
      foods: ["Remember Me"],
      show: true,
      labelsEnglish: {
        title: "Student-Class Mapping",
        studentName: "Student",
        className: "Class",
        submit: "Submit",
        reset: "Reset"
      },
      labels: {
        title: "Student-Class Mapping",
        studentName: "विद्यार्थी",
        className: "वर्ग:",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित"
      },
      labelsMarathi: {
        title: "विद्यार्थी-वर्ग: ",
        studentName: "विद्यार्थी",
        className: "वर्ग:",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित"
      },
        items: [
            {student_name: 'Test',class_standard:2,division:'B',ac_year:2021}
        ],
        fields: [
            { key: 'student_name', label: 'Student', sortable: true, class: 'class1'},
            { key: 'class_standard', label: 'Class', sortable: true, class: 'class2'},
            { key: 'division', label: 'Division', sortable: true, class: 'class3'},
            { key: 'ac_year', label: 'Year', sortable: true, class: 'class3'}
            
            //{ key: 'actions', label: 'Action' }
        ],
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn:[]

    };
  },
  methods:{
      map_student(){
          alert("Here");
         this.$router.push('/student_class');
      },
      get_mapped_data(){
      var promise = apis.list_studentClassMapping().then( response => {
        this.items = response.data;
        this.totalRows = this.items.length;
      }).catch(error => {

      })
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.dispatch("login", {
        token: "abcd",
        user: this.form
      });
      this.$router.replace("/dashboard");
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.userName = "";
      this.form.password = "";
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
     
  
  },
  computed:{
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
  created(){
      this.get_mapped_data();
  }
};
</script>
